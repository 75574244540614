.portfolio-item {
    flex: 1;
    margin-top: -0.1rem;
    background-color: white;
    height: 100%;
    overflow: scroll;

    border: 0.05rem solid black;
    border-radius: 0.2rem;
    border-top-left-radius: 0rem;

    flex-direction: column;
    align-items: center;

    .body-container {
        margin: 1rem 0;
        max-width: 95%;

        align-items: center;
        justify-content: space-evenly;

        .column {
            flex-direction: column;
            justify-content: center;
            max-width: 95%;

            &.left-container {
                flex-basis: 70%;

                @media screen and (max-width: 820px) {
                    order: 1;
                    margin-bottom: 0.25rem;
                }
            }
            &.right-container {
                height: 100%;
                flex-basis: 25%;
                align-items: center;
                justify-content: center;
                margin-left: 2rem;

                img {
                    max-height: 30rem;
                    max-width: 17rem;
                }

                @media screen and (max-width: 820px) {
                    order: 0;

                    img {
                        margin: 1rem 0;
                    }
                }
            }
            &.single-col {
                flex-basis: 95%;

                .section {
                    font-size: 1.25rem;
                    label {
                        width: 7rem;
                    }
                }
            }

            .section {
                margin: 0.75rem 0rem;
                font-size: 1rem;

                @media screen and (max-width: 500px) {
                    font-size: 1.25rem;
                }

                label {
                    font-weight: bold;
                    margin-right: 2rem;
                    width: 7rem;
                    justify-content: flex-end;
                }

                p {
                    flex: 1;
                    margin: 0;
                    text-align: left;
                    white-space: pre-wrap;
                }
            }
        }
    }

    .links-container {
        margin-bottom: 1rem;
        align-self: center;

        a {
            margin: 0 0.25rem;

            svg {
                height: 2.5rem;
                width: 2.5rem;
                color: black;

                &:hover {
                    color: grey;
                }
            }
        }
    }

}