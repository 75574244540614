.top-container {
    height: 100%;
    width: 95%;
    @media screen and (max-width: 500px) {
        width: 95%;
    }
    overflow: scroll;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
        justify-content: unset;
    }

    .sidebar {
        flex-basis: 35%;
        min-height: 25.5rem;
        min-width: 22rem;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 500px) {
            flex: 1;
        }

        .sidebar-items-container {
            margin-top: 3rem;
            flex-direction: column;
            align-items: center;

            .title {
                font-size: 3.5rem;
                text-align: start;
                margin-top: 0.5rem;
                align-self: flex-start;
                justify-self: flex-start;
            }

            .picture {
                height: 15rem;
                width: 15rem;

                align-items: center;
                justify-content: center;

                label {
                    font-size: 2rem;
                }
            }

            .links-container {
                a {
                    margin: 0.25rem;

                    svg {
                        height: 2.5rem;
                        width: 2.5rem;
                        color: black;

                        &:hover {
                            color: grey;
                        }
                    }
                }
            }
        }
    }
}
