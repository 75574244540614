.resume {
    width: 100%;
    height: 100%;

    .company-buttons {
        align-self: center;
        flex-basis: 100%;
        height: 45%;
        justify-content: center;
        align-items: center;
    }

}