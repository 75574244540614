@import "../../mixins.scss";

.company-button {
    @include hand-drawn-border-medium;

    align-self: center;
    flex-basis: 30%;
    min-width: 23rem;
    min-height: 15rem;
    margin: 2rem;
    padding: 1rem;

    border-style: solid;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    &:hover {
        border-style: dashed;
        border-color: grey;
    }

    .company-logo {
        height: 8rem;
        width: 8rem;
        margin-bottom: 1rem;
    }

    .title {
        margin-top: 0rem;
        font-size: 2rem;
    }

    .job-title {
        font-size: 1.5rem;
    }

}