.about-container {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    margin: 0.5rem 0;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        flex: 1;
        justify-content: start;
    }
}