@import "./mixins.scss";

.screen {
  @include hand-drawn-border-large;

  position: absolute;
  height: 94%;
  width: 90%;
  margin-left: 5%;

  font-size: 3em;

  justify-content: center;
  align-items: center;
}