.about-section {

    flex-direction: column;
    align-items: center;

    &.open {
        margin-bottom: 1rem;
    }

    .title-container {
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
            .title {
                border-bottom: 0.1rem solid black;
                @media only screen and (max-width: 500px) {
                    border-bottom: unset;
                }
            }
        }

        .title {
            max-width: 100%;
            min-width: 93%;
            font-size: 2.25rem;
            margin: 0.8rem;
            border-bottom: 0.1rem solid transparent;
            line-height: 0.8;
            display: inline-block;

            @media screen and (max-width: 500px) {
                line-height: unset;
                font-size: 2rem;
            }
        }

        img {
            margin-top: -0.2rem;
            height: 1.5rem;
        }
    }

    .body {
        max-width: 90%;
        font-size: 1.25rem;
        margin: 0;
        white-space: pre-wrap;
        text-align: center;
    }
}