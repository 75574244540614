$tab-horizontal-margin: 0.1rem;
$tab-horizontal-padding: 1.25rem;
$tab-radius: 0.4rem;

.tab {
    font-size: 1.5rem;
    padding: .25rem $tab-horizontal-padding .1rem $tab-horizontal-padding;
    margin: 0rem $tab-horizontal-margin;

    border: 0.05rem solid black;
    border-bottom: none;
    border-top-left-radius: $tab-radius;
    border-top-right-radius: $tab-radius;

    &.selected {
        z-index: 1000;
        background-color: white;
        margin-bottom: 0.05rem;

        @media screen and (max-width: 500px) {
            margin: 0;
        }
    }

    &.first {
        margin-left: -.01rem;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.25rem;
    }
    @media screen and (max-width: 590px) {
        font-size: 1rem;
    }
    @media screen and (max-width: 500px) {
        padding: .25rem .5rem .1rem .5rem;
    }
}