.company {
    // flex-basis: 100%;
    align-items: center;
    flex-direction: column;
    max-height: 100%;
    min-height: 100%;
    overflow: scroll;

    .close-btn {
        height: 3rem;
        width: 3rem;

        position: absolute;
        top: 0.5rem;
        right: 0.75rem;

        color: black;

        &:hover {
            color: grey;
        }
    }

    .image-container {
        flex: 0 0 8rem;
        margin-top: 3.5rem;
        flex-direction: column;
        align-items: center;
        .company-logo {
            height: 5rem;
            width: 5rem;
            margin: .5rem;
        }
        .title {
            font-size: 2rem;
        }
    }

    .details-container {
        flex-grow: 0;
        flex-shrink: 0;
        width: 80%;
        margin: 2rem 0 0rem 0;

        @media screen and (max-width: 500px) {
            width: 95%;
        }

        flex-direction: column;
        justify-content: center;

        .section {
            flex-shrink: 0;
            margin: 0.75rem 0.5rem 0.75rem 0rem;
            font-size: 1.25rem;

            .section-body-container {
                flex-direction: column;
                flex: 1;

                ul {
                    margin-top: 0.5rem;
                    flex-direction: column;
                    margin-right: 0.75rem;

                    li {
                        display: list-item !important;
                        @media screen and (max-width: 500px) {
                            margin-left: -1.5rem;
                        }
                    }
                }
            }

            label {
                font-weight: bold;
                margin-right: 2rem;
                flex-basis: 7rem;
                justify-content: flex-end;
            }

            p {
                flex: 1;
                margin: 0;
                text-align: left;
                white-space: pre-wrap;
                min-height: fit-content;
            }
        }
    }

    .links-container {
        margin-bottom: 1rem;

        a {
            margin: 0.25rem;

            svg {
                height: 2.5rem;
                width: 2.5rem;
                color: black;

                &:hover {
                    color: grey;
                }
            }
        }
    }
}