html {
  height: 100%;

  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }

  @media screen and (max-width: 960px) {
    font-size: 12px;
  }

  @media screen and (max-width: 820px) {
    .shrink-changes-flex-direction {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
}

html, body, #root {
  height: 100%;
  width: 100%;
  font-family: "coming-soon", sans-serif;
  cursor: default;
}

#root * {
  display: flex;
  box-sizing: content-box;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.page-container {
  flex-direction: column;
  height: 100%;

  .navbar {
    margin: .6rem 5% 0rem 5%;
    flex: 0 0 4rem;
    justify-content: center;
    align-items: center;
  }

  .screen-container {
    flex: 1 0 auto;
    align-items: center;
    position: relative;

    .screen-right-enter {
      transform: translate(115%);
    }

    .screen-right-enter-active {
      transform: translate(0%);
      transition: transform 700ms ease-in-out;
    }

    .screen-right-exit {
      transform: translate(0%);
    }

    .screen-right-exit-active {
      transform: translate(-115%);
      transition: transform 700ms ease-in-out;
    }

    .screen-left-enter {
      transform: translate(-115%);
    }

    .screen-left-enter-active {
      transform: translate(0%);
      transition: transform 700ms ease-in-out;
    }

    .screen-left-exit {
      transform: translate(0%);
    }

    .screen-left-exit-active {
      transform: translate(115%);
      transition: transform 700ms ease-in-out;
    }
  }
}