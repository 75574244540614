$border-thickness: 0.15rem;

@mixin hand-drawn-border-small {
    border: $border-thickness solid black;
    border-top-left-radius: 400px 50px;
    border-top-right-radius: 50px 400px;
    border-bottom-right-radius: 400px 50px;
    border-bottom-left-radius: 50px 400px;
}

@mixin hand-drawn-border-medium {
    border: $border-thickness solid black;
    border-top-left-radius: 200px 10px;
    border-top-right-radius: 10px 200px;
    border-bottom-right-radius: 200px 10px;
    border-bottom-left-radius: 10px 200px;
}

@mixin hand-drawn-border-large {
    border: $border-thickness solid black;
    border-top-left-radius: 400px 7px;
    border-top-right-radius: 7px 400px;
    border-bottom-right-radius: 400px 7px;
    border-bottom-left-radius: 7px 400px;
}
