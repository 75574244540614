@import "./mixins.scss";

.nav-button {
    @include hand-drawn-border-small;

    flex: 1;
    min-width: 7rem;
    max-width: 20rem;
    margin: 0 1.25rem;
    height: 2.75rem;

    font-family: "blokletters", sans-serif;
    font-size: 1.75rem;

    align-items: center;
    justify-content: center;

    label {
        margin-top: .25rem;
    }

    &.selected {
        border-style: dashed;
    }

    &:hover {
        border-style: dashed;
        border-color: grey;
    }

    @media screen and (max-width: 500px) {
        font-size: 1.4rem;
    }
}